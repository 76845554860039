import React, { useEffect, useReducer } from 'react';
import Table from '@material-ui/core/Table';
import { StyleSheet } from '@react-pdf/renderer';
import __ from 'underscore';
import { Icon, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import Select2 from 'react-select';
import { Button, Spinner } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../Layout/ChangeAppLanguageFront';

const tlang = ChangeAppLanguageFront.translateLanguage;
const client_report_aexdo = tlang('client_report_aexdo') || 'Aexdo';
const client_report_analytical_results =
	tlang('client_report_analytical_results') || 'Analytical results';
const client_campaign_project = tlang('client_campaign_project') || 'Project';
const client_project_address = tlang('client_project_address') || 'Address';
const dashboard_btn_origin = tlang('dashboard_btn_origin') || 'Origin';
const client_btn_boundary = tlang('client_btn_boundary') || 'Boundary';
const dashboard_btn_destination = tlang('dashboard_btn_destination') || 'Destination';
const analysis_pollution = tlang('analysis_pollution') || 'Pollution';
const client_probing_campaign = tlang('client_probing_campaign') || 'Campaign';
const client_probing_txt = tlang('clientmenu_probing') || 'Probing';
const client_Depth = tlang('client_Depth') || 'Depth';
const client_sample = tlang('client_sample') || 'Sample';
const client_Observation = tlang('client_Observation') || 'Observation';
const client_disposal_process = tlang('client_disposal_process') || 'Disposal process';
const client_probing_layer_lithology = tlang('client_probing_lithology') || 'Lithology';
const client_probing_layer_colour = tlang('client_campaign_color') || 'Colour';
const dashboard_log_date = tlang('dashboard_log_date') || 'Date';
const client_Anomaly = tlang('client_Anomaly') || 'Anomaly';
const client_probing_layer = tlang('client_probing_layer') || 'Layer';
const cancel_btn = tlang('cancel_btn') || 'Cancel';
const common_btn_save = tlang('common_btn_save') || 'Save';
const client_sample_observation = tlang('client_sample_observation') || 'Sample observation';
const client_layer_observation = tlang('client_layer_observation') || 'Layer observation';

const initialState = {
	desposalList: [],
	forcedSamples: [],
};

function reducer(state, action) {
	switch (action.type) {
		default:
			const upd = {};
			for (const key of Object.keys(action)) {
				upd[key] = action[key];
			}
			return { ...state, ...upd };
	}
}

export default function AnalysisReportsTable({
	reportData,
	onUpdateSampleSetting,
	onClose,
	initialForcedSampleData,
	loaderForcedSamples,
}) {
	const styles = StyleSheet.create({
		forcedDispAsterisk: {
			fontSize: '19px',
			color: '#000',
			fontWeight: 'bold',
			marginInlineStart: '1px',
		},
		page: {
			backgroundColor: '#ffffff',
			padding: 20,
		},
		headingTexts: {
			textAlign: 'center',
			fontSize: '40px',
			marginBottom: '10px',
		},
		headerColoredBox: {
			backgroundColor: '#0d6dbd',
			fontSize: '20px',
			color: '#FFFFFF',
			border: '1px solid #000',
			width: 200,
			padding: '5px',
			textAlign: 'center',
		},
		headerWhitedBox: {
			fontSize: '20px',
			color: '#000',
			border: '1px solid #000',
			width: 500,
			padding: '5px',
		},
		headerProbingSampleColoredBox: {
			backgroundColor: '#0d6dbd',
			flexDirection: 'row',
			color: '#FFFFFF',
			border: '1px solid #000',
			width: 300,
			textAlign: 'center',
			margin: '0px',
			fontSize: 14,
			padding: '2px 5px 1px 5px',
		},
		headerProbingSampleColoredBoxLithology: {
			backgroundColor: '#0d6dbd',
			flexDirection: 'row',
			color: '#FFFFFF',
			border: '1px solid #000',
			width: 300,
			height: 40,
			textAlign: 'center',
			margin: '0px',
			fontSize: 14,
			padding: '10px 5px 1px 5px',
		},
		testFamilyName: {
			width: 300,
			flexDirection: 'row',
			fontSize: 14,
			border: '1px solid #000',
			padding: '2px 5px 1px 5px',
			textAlign: 'center',
			margin: '0px',
			whiteSpace: 'nowrap',
		},
		disposalNameAtLast: {
			width: 300,
			flexDirection: 'row',
			fontSize: 17,
			border: '1 solid #000',
			padding: '4px 5px 1px 5px',
			textAlign: 'center',
			margin: '0px',
			backgroundColor: '#0d6dbd',
			color: '#FFFFFF',
		},
		section: {
			margin: 10,
			padding: 10,
			flexGrow: 1,
		},
		coloumn: {
			flexDirection: 'row',
			marginTop: 10,
		},
		fullWidth: {
			flexDirection: 'row',
		},
		row: {
			width: 200,
			flexDirection: 'row',
			fontSize: 14,
			border: '1 solid #000',
			padding: '2px 5px 1px 5px',
		},
		header: {
			width: 200,
			flexDirection: 'row',
			fontSize: 14,
			border: '1 solid #000',
			padding: '2px 5px 1px 5px',
			backgroundColor: '#ff8100',
		},
		campaign_name: {
			fontSize: 14,
		},
		headerDetails: {
			paddingBottom: 10,
		},
		disposal_header: {
			width: 150,
			// maxWidth: 150,
			// overflow: 'auto',
			whiteSpace: reportData?.widerColumns ? 'nowrap' : 'pre-line',
			flexDirection: 'row',
			fontSize: 14,
			border: '1px solid #000',
			padding: '2px 5px 1px 5px',
			textAlign: 'left',
			verticalAlign: 'top',
			margin: '0px',
		},
		disposal_header_lithology: {
			width: 150,
			height: 'auto',
			minHeight: 40,
			flexDirection: 'row',
			fontSize: 14,
			border: '1px solid #000',
			padding: '2px 5px 1px 5px',
			textAlign: 'center',
			margin: '0px',
			whiteSpace: 'nowrap',
		},
		probingReportHeader: {
			flexDirection: 'row',
			textAlign: 'center',
			backgroundColor: '#6da8cf',
			color: '#000',
			fontSize: '20px',
		},
		probingReportHeaderData: {
			flexDirection: 'row',
			textAlign: 'center',
		},
		probingReportNotesHeading: {
			flexDirection: 'row',
			textAlign: 'center',
			backgroundColor: '#6da8cf',
			color: '#000',
			fontSize: '20px',
			padding: '20px',
			border: '1px soled #000',
		},
		probingReportNotesBox: {
			flexDirection: 'row',
			textAlign: 'center',
			color: '#000',
			fontSize: '20px',
			padding: '20px',
			border: '1px soled #000',
		},
		width_100: {
			width: 100,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
		width_150: {
			width: 150,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
		width_200: {
			width: 200,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
		width_250: {
			width: 250,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
		width_300: {
			width: 300,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
		width_400: {
			width: 400,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
		width_500: {
			width: 500,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
		width_600: {
			width: 600,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
		width_900: {
			width: 900,
			border: '1px solid #000',
			padding: '20px',
			textAlign: 'center',
		},
	});

	const styleshtml = StyleSheet.create({
		heightthirty: {
			height: '30px',
		},

		page: {
			backgroundColor: '#ffffff',
			padding: 20,
			borderCollapse: 'collapse',
			//border: "none !important",
		},

		headingTexts: {
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: '16px',
			//marginBottom:"10px"
		},
		headingTexts1: {
			textAlign: 'center',
			marginBottom: '10px',
		},

		headerColoredBox: {
			backgroundColor: '#0d6dbd',
			fontSize: '13px',
			color: '#FFFFFF',
			border: '1px solid #000',
			width: 200,
			//padding:"5px",
			textAlign: 'center',
		},
		headerWhitedBox: {
			fontSize: '13px',
			color: '#000',
			border: '1px solid #000',
			width: 500,
			//padding:"5px",
			// textAlign: "center"
			textAlign: 'left',
			paddingLeft: '1rem',
		},

		testFamilyName: {
			backgroundColor: '#0d6dbd',
			color: '#FFFFFF',
			width: 300,
			flexDirection: 'row',
			fontSize: 13,
			border: '1px solid #000',
			padding: '2px 5px 1px 5px',
			textAlign: 'center',
			margin: '0px',
			whiteSpace: 'nowrap',
		},

		disposalNameAtLast: {
			width: 300,
			flexDirection: 'row',
			fontSize: 16,
			border: '1px solid #000',
			padding: '4px 5px 1px 5px',
			textAlign: 'center',
			margin: '0px',
			backgroundColor: '#0d6dbd',
			color: '#FFFFFF',
		},
	});

	let heigth = 1000;
	let width = 2000;
	let total_sample_per_row = 0;
	let total_selected_desposal = 0;
	let totalColspan = 0;
	let hideDisposalInReport = false;
	let disposalThresholdBeforeSamples = false;
	let fromPage = '';
	let isPageSampleDisposalSetting = false;
	let disposalRowSpan = '8';
	if (reportData) {
		if (reportData.heigth) {
			heigth = reportData.heigth;
		}
		if (reportData.width) {
			width = reportData.width;
		}

		fromPage = reportData?.from;
		if (fromPage === 'sampleDisposalSetting') {
			isPageSampleDisposalSetting = true;
			disposalRowSpan = '8';
		}

		total_sample_per_row =
			reportData?.dataToPrint &&
			reportData?.dataToPrint.length &&
			reportData?.dataToPrint[0][0].samples.length;
		total_selected_desposal = reportData?.desposal_list?.length;
		totalColspan = parseInt(total_sample_per_row) + parseInt(total_selected_desposal) + 1;
		hideDisposalInReport = reportData?.hideDisposalInReport;
		disposalThresholdBeforeSamples = reportData?.disposalThresholdBeforeSamples;
	}

	const [state, localDispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		if (initialForcedSampleData?.length > 0) {
			localDispatch({
				forcedSamples: initialForcedSampleData,
			});
		} else {
			localDispatch({
				forcedSamples: [],
			});
		}
	}, [JSON.stringify(initialForcedSampleData)]);

	useEffect(() => {
		const desposalList = [];
		reportData.desposal_list.map(d => {
			desposalList.push({ value: d.desposal_id, label: d.name, color: d.color });
		});
		localDispatch({
			desposalList: desposalList,
		});
	}, [reportData?.desposal_list?.length]);

	function removeMatchedDisposal(tempMatched) {
		const obj = {
			sampleCampaign: tempMatched.sample_campaign,
			sampleName: tempMatched.sample_name,
			desposalId: tempMatched.last_matched_desposal.desposal_id,
			desposalName: tempMatched.last_matched_desposal.name,
			forceSelected: false,
		};
		let forcedSamples = [...state.forcedSamples];
		forcedSamples = forcedSamples.concat(obj);
		localDispatch({
			forcedSamples: forcedSamples,
		});
	}

	function resetMatchedDisposal(sample_name) {
		let forcedSamples = [...state.forcedSamples];
		forcedSamples = forcedSamples.filter(s => s.sampleName !== sample_name);
		localDispatch({
			forcedSamples: forcedSamples,
		});
	}

	function isExistSample(sample_name) {
		return state.forcedSamples.filter(s => s.sampleName === sample_name).length ? true : false;
	}

	function getForcedSamples() {
		const forcedSamples = [...state.forcedSamples];
		let forceSelected = forcedSamples.filter(s => s.forceSelected === true);
		onUpdateSampleSetting(forceSelected);
	}

	function getDynamicWidth(layer_observation, layer_anomaly) {
		let widthL = 11;
		let contentWidth = Math.max(layer_observation.length, layer_anomaly.length);
		if (layer_observation || layer_anomaly) {
			if (contentWidth <= 28) {
				widthL = contentWidth / 3;
			} else if (contentWidth <= 35) {
				widthL = contentWidth / 3.8;
			} else if (contentWidth <= 40) {
				widthL = contentWidth / 4.3;
			} else if (contentWidth <= 50) {
				widthL = contentWidth / 4.4;
			} else if (contentWidth <= 66) {
				widthL = contentWidth / 4.7;
			} else if (contentWidth > 66 && contentWidth <= 85) {
				widthL = contentWidth / 4.9;
			} else {
				widthL = contentWidth / 5.5;
			}
		}
		return {
			width: 'auto',
			minWidth: `${widthL}em`,
			maxWidth: 450,
			// maxWidth: 150,
			// overflow: 'auto',
			whiteSpace: reportData?.widerColumns ? 'nowrap' : 'pre-line',
			flexDirection: 'row',
			fontSize: 14,
			border: '1px solid #000',
			padding: '2px 5px 1px 5px',
			textAlign: 'left',
			verticalAlign: 'top',
			margin: '0px',
		};
	}
 
	function getDisposalHeaderHTML(probingArr) {
		hideDisposalInReport == false &&
			reportData.desposal_list.length !== 0 &&
			reportData.desposal_list.map(disposal => {
				let header_css = {
					//width: 200,
					width: 100,
					flexDirection: 'row',
					fontSize: 16,
					border: '1px solid #000',
					padding: '5px',
					textAlign: 'center',
					backgroundColor: '' + disposal.color + '',
				};
				// headingRow.push(
				// 	<td style={header_css} key={`${disposal.disposal_id}__${Math.random()}`}>{disposal.name}</td>
				// )

				probingArr.push(
					<td
						rowSpan={disposalRowSpan}
						style={header_css}
						key={`${disposal.disposal_id}__${Math.random()}`}
					>
						{disposal.name}
					</td>
				);
			});
	}

	function getDisposalThresholdValues(res, singleRow) {
		hideDisposalInReport == false &&
			reportData.desposal_list.length !== 0 &&
			reportData.desposal_list.map((disposal, dkey) => {
				let header_css = {
					//width: 200,
					width: 100,
					flexDirection: 'row',
					fontSize: 14,
					border: '1px solid #000',
					padding: '2px 5px 1px 5px',
					textAlign: 'center',
				};
				let checkDisposal = res.disposals.filter(d => d.desposal_id === disposal.desposal_id);
				if (checkDisposal.length !== 0) {
					return singleRow.push(
						<td style={header_css} key={`${disposal.disposal_id}_${Math.random()}_`}>
							{checkDisposal[0].rules_val ? checkDisposal[0].rules_val : ''}
						</td>
					);
				} else {
					return singleRow.push(
						<td style={header_css} key={`${disposal.disposal_id}_${Math.random()}_`}>
							{}
						</td>
					);
				}
			});
	}

	function getDisposalProcessEmptyCell(matchedDisposal) {
		hideDisposalInReport == false &&
			reportData.desposal_list.length !== 0 &&
			reportData.desposal_list.map(disposal => {
				let header_css = {
					//width: 200,
					width: 100,
					flexDirection: 'row',
					border: 'none !important',
				};
				return matchedDisposal.push(
					<th style={header_css} key={`${Math.random()}`}>
						{' '}
					</th>
				);
			});
	}

	return (
		<>
			{reportData && (
				<>
					{reportData != '' &&
					!__.isEmpty(reportData) &&
					reportData.result_data.length &&
					reportData.sample_list.length ? (
						<>
							<div style={{ display: 'grid' }}>
								<div
									id="analysisReport"
									className="table-responsive reportanalysisResult reportHeight"
									style={{ whiteSpace: reportData?.widerColumns ? 'nowrap' : 'normal' }}
								>
									{reportData ? (
										<>
											{reportData.dataToPrintPdf &&										
												reportData.dataToPrintPdf.map((maintest, key) => {
													//var headingRow = []
													var allRows = [];
													let lastWhitedBox = {
														fontSize: '20px',
														color: '#FFFFFF',
														border: '1px solid #000',
														padding: '5px',
														textAlign: 'center',
														width: 1000 + reportData.desposal_list.length * 200,
													};
													var headerDetails = [];
													var probingArr = [];
													var sampleArr = [];
													var sampleObservationArr = [];
													var depthArr = [];
													var lithologyArr = [];
													var colourArr = [];
													var anomalyArr = [];
													var layerObservationArr = [];
													//headerDetails.push(<tr className="reportFirstrow" style={styleshtml.headingTexts} key={`${Math.random()}`}><td colspan={totalColspan}>{client_report_aexdo} <br></br> {client_report_analytical_results}</td></tr>)
													//headerDetails.push(<tr style={styleshtml.headingTexts} key={`${Math.random()}`}><td colspan={totalColspan}>{client_report_analytical_results}</td></tr>)
													headerDetails.push(
														<tr style={styles.fullWidth} key={`${Math.random()}`}>
															<td style={styleshtml.headerColoredBox}>{client_campaign_project}</td>
															<td colspan={4} style={styleshtml.headerWhitedBox}>
																{reportData.project_name}
															</td>
															<td style={styleshtml.headerColoredBox}>{client_project_address}</td>
															<td colspan={5} style={styleshtml.headerWhitedBox}>
																{reportData.project_address}{' '}
															</td>
															<td style={styleshtml.headerColoredBox}>{dashboard_log_date}</td>
															<td colspan={totalColspan - 12} style={styleshtml.headerWhitedBox}>
																{reportData.project_date}
															</td>
														</tr>
													);

													if (
														reportData.origin_name ||
														reportData.destination_name ||
														reportData.pollutionzone_name ||
														reportData.boundary_name
													) {
														headerDetails.push(
															<tr style={styles.fullWidth} key={`${Math.random()}`}>
																<td style={styleshtml.headerColoredBox}>{dashboard_btn_origin}</td>
																<td colspan={2} style={styleshtml.headerWhitedBox}>
																	{reportData.origin_name}
																</td>
																<td style={styleshtml.headerColoredBox}>
																	{dashboard_btn_destination}
																</td>
																<td colspan={5} style={styleshtml.headerWhitedBox}>
																	{reportData.destination_name}{' '}
																</td>
																<td style={styleshtml.headerColoredBox}>{client_btn_boundary}</td>
																<td colspan={2} style={styleshtml.headerWhitedBox}>
																	{reportData.boundary_name}
																</td>
																<td style={styleshtml.headerColoredBox}>{analysis_pollution}</td>
																<td colspan={totalColspan - 13} style={styleshtml.headerWhitedBox}>
																	{reportData.pollutionzone_name}
																</td>
															</tr>
														);
													}
													headerDetails.push(
														<tr style={styles.fullWidth} key={`_${Math.random()}_`}>
															<td style={styleshtml.headerColoredBox}>{client_probing_campaign}</td>
															<td colspan={totalColspan - 1} style={styleshtml.headerWhitedBox}>
																{reportData.campaign_name}
															</td>
														</tr>
													);
													reportData.dataToPrint[key][0].samples.map((s, smkye) => {
														let header_css = {
															width: 150,
															flexDirection: 'row',
															fontSize: 13,
															border: '1px solid #000',
															padding: '2px 5px 1px 5px',
															backgroundColor: '#FFF',
															textAlign: 'center',
															whiteSpace: 'nowrap',
														};
														let header_css_sample_obs = {
															width: 150,
															flexDirection: 'row',
															fontSize: 13,
															border: '1px solid #000',
															padding: '2px 5px 1px 5px',
															backgroundColor: '#FFF',
															textAlign: 'center',
														};
														let header_css_no_border = {
															width: 150,
															flexDirection: 'row',
															fontSize: 13,
															//border: "1px solid #000",
															padding: '2px 5px 1px 5px',
															backgroundColor: '#FFF',
															textAlign: 'center',
														};
														if (s.sample_name) {
															let lowerSampleLength = '';
															let upperSampleLength = '';
															if (s.sample_length) {
																let sample_length_arr = s.sample_length.split(' - ');
																lowerSampleLength = Number(sample_length_arr[0]).toFixed(2);
																upperSampleLength = Number(sample_length_arr[1]).toFixed(2);
															}

															// headingRow.push(
															// 	<tr key={`${smkye}__${Math.random()}__${s.sample_name}`}>
															// 		<td style={styles.disposal_header} key={`${s.probingcode}_${Math.random()}`}>
															// 			{s.probingcode}
															// 		</td>
															// 		<td style={styles.disposal_header} key={`${s.sample_name_range}__${Math.random()}`}>
															// 			{s.sample_name_range}
															// 		</td>
															// 		<td style={styles.disposal_header} key={`${s.sample_observation}__${Math.random()}`}>
															// 			{s.sample_observation}
															// 		</td>
															// 		<td style={styles.disposal_header} key={`${s.sample_length}___${Math.random()}`}>
															// 			{/* {s.sample_length?(Number(s.sample_length)/100).toFixed(2):"-"} */}
															// 			{s.sample_length ? s.sample_length : "-"}
															// 		</td>
															// 		<td style={styles.disposal_header_lithology} key={`${s.lithology}__${Math.random()}`}>
															// 			{s.lithology}
															// 		</td>

															// 		<td style={styles.disposal_header} key={`${s.colourname}__${Math.random()}`}>
															// 			{s.colourname}
															// 		</td>
															// 	</tr>
															// )
															if (smkye == 0) {
																probingArr.push(
																	<td style={styleshtml.testFamilyName}>{client_probing_txt}</td>
																);
																sampleArr.push(
																	<td style={styleshtml.testFamilyName}>{client_sample}</td>
																);
																{
																	reportData?.lockHeader &&
																		sampleObservationArr.push(
																			<td style={styleshtml.testFamilyName}>
																				{client_sample_observation}
																			</td>
																		);
																	depthArr.push(
																		<td style={styleshtml.testFamilyName}>{client_Depth}</td>
																	);
																	lithologyArr.push(
																		<td style={styleshtml.testFamilyName}>
																			{client_probing_layer_lithology}
																		</td>
																	);
																	colourArr.push(
																		<td style={styleshtml.testFamilyName}>
																			{client_probing_layer_colour}
																		</td>
																	);
																}
																if (
																	isPageSampleDisposalSetting === true &&
																	reportData?.lockHeader
																) {
																	anomalyArr.push(
																		<td style={styleshtml.testFamilyName}>{client_Anomaly}</td>
																	);
																}
																layerObservationArr.push(
																	<td style={styleshtml.testFamilyName}>
																		{client_layer_observation}
																	</td>
																);

																{
																	disposalThresholdBeforeSamples &&
																		getDisposalHeaderHTML(probingArr);
																}
															}
															probingArr.push(
																<td style={header_css} key={`${s.probingcode}_${Math.random()}`}>
																	{s.probingcode}
																</td>
															);
															sampleArr.push(
																<td
																	style={header_css}
																	key={`${s.sample_name_range}__${Math.random()}`}
																>
																	{s.sample_name_range}
																</td>
															);
															{
																reportData?.lockHeader &&
																	sampleObservationArr.push(
																		<td
																			style={header_css_sample_obs}
																			key={`${s.sample_observation}__${Math.random()}`}
																		>
																			{s.sample_observation}
																		</td>
																	);
																depthArr.push(
																	<td
																		style={header_css}
																		key={`${s.sample_length}___${Math.random()}`}
																	>
																		{/* {s.sample_length?(Number(s.sample_length)/100).toFixed(2):"-"} */}
																		{/* {s.sample_length ? s.sample_length : "-"} */}
																		{s.sample_length
																			? lowerSampleLength + ' - ' + upperSampleLength
																			: '-'}
																	</td>
																);
																lithologyArr.push(
																	<td
																		style={styles.disposal_header_lithology}
																		key={`${s.lithology}__${Math.random()}`}
																	>
																		{s.lithology}
																	</td>
																);
																colourArr.push(
																	<td
																		style={styles.disposal_header_lithology}
																		key={`${s.colourname}__${Math.random()}`}
																	>
																		{s.colourname}
																	</td>
																);
															}
															if (isPageSampleDisposalSetting === true && reportData?.lockHeader) {
																anomalyArr.push(
																	<td
																		style={styles.disposal_header}
																		key={`${s.layer_anomaly}__${Math.random()}`}
																	>
																		{s.layer_anomaly}
																	</td>
																);
															}
															layerObservationArr.push(
																<td
																	style={getDynamicWidth(s.layer_observation, s.layer_anomaly)}
																	key={`${s.layer_observation}__${Math.random()}`}
																>
																	{s.layer_observation}
																</td>
															);
														} else {
															// headingRow.push(
															// 	<tr key={`${smkye}__${Math.random()}__${s.sample_name}`}>
															// 		<td style={{ width: 150 }}></td>
															// 		<td style={{ width: 150 }}></td>
															// 		<td style={{ width: 150 }}></td>
															// 		<td style={{ width: 150 }}></td>
															// 		<td style={{ width: 150 }}></td>
															// 	</tr>
															// )
															probingArr.push(<th style={header_css_no_border}></th>);
															sampleArr.push(<th style={header_css_no_border}></th>);
															sampleObservationArr.push(<th style={header_css_no_border}></th>);
															depthArr.push(<th style={header_css_no_border}></th>);
															lithologyArr.push(<th style={header_css_no_border}></th>);
															colourArr.push(<th style={header_css_no_border}></th>);
															if (isPageSampleDisposalSetting === true) {
																anomalyArr.push(<th style={header_css_no_border}></th>);
															}
															layerObservationArr.push(<th style={header_css_no_border}></th>);
														}
													});

													{
														!disposalThresholdBeforeSamples && getDisposalHeaderHTML(probingArr);
													}

													let test_header_css = {
														flexDirection: 'row',
														fontSize: 14,
														border: '1px solid #000',
														padding: '3px',
														textAlign: 'center',
														backgroundColor: '#0d6dbd',
														width: '100%',
														color: '#FFF',
													};
													//Main test row data
													maintest.map((result, k) => {
														allRows.push(
															<tr
																className="stickyTestFamily"
																style={test_header_css}
																key={`${Math.random()}`}
															>
																<th style={styleshtml.testFamilyName}>{result.family_name}</th>
																<th colspan={totalColspan - 1}></th>
															</tr>
														);
														result.tests_list.map((res, tk) => {
															let singleRow = [];
															singleRow.push(
																<td style={styles.testFamilyName} key={`${tk}__${res.testFamily}`}>
																	{res.testFamily}
																</td>
															);
															{
																disposalThresholdBeforeSamples &&
																	getDisposalThresholdValues(res, singleRow);
															}
															res.samples.map((s, smplkey) => {
																let header_css = {
																	width: 150,
																	flexDirection: 'row',
																	fontSize: 14,
																	border: '1px solid #000',
																	padding: '2px 5px 1px 5px',
																	backgroundColor: '#FFF',
																	textAlign: 'center',
																};
																if (s.hasOwnProperty('matched_desp')) {
																	if (s.matched_desp.hasOwnProperty('color')) {
																		header_css.backgroundColor = s.matched_desp.color;
																	}
																}
																if (s.hasOwnProperty('stringnotmatched')) {
																	if (s.stringnotmatched == true) {
																		header_css.backgroundColor = 'lightgrey';
																	}
																}

																if (s.sample_name) {
																	singleRow.push(
																		<td style={header_css} key={`${tk}__${smplkey}`}>
																			{s.value}
																		</td>
																	);
																} else {
																	singleRow.push(
																		<td style={header_css} key={`${tk}__${smplkey}`}></td>
																	);
																}
															});

															{
																!disposalThresholdBeforeSamples &&
																	getDisposalThresholdValues(res, singleRow);
															}
															allRows.push(
																<tr style={styles.fullWidth} key={`${Math.random()}`}>
																	{singleRow}
																</tr>
															);
														});
													});

													let matchedDisposal = [];

													/* Display empty cell for disposal process row */
													{
														disposalThresholdBeforeSamples &&
															getDisposalProcessEmptyCell(matchedDisposal);
													}

													reportData.dataToPrint[key][0].samples.map(ss => {
														let temmMatched = reportData.higestMatchedDisposals[key].filter(
															dd => dd.sample_name == ss.sample_name
														);
														let bg_color = '';
														let d_name = '';
														if (temmMatched.length !== 0) {
															if (temmMatched[0].last_matched_desposal.hasOwnProperty('color')) {
																bg_color = temmMatched[0].last_matched_desposal.color;
																d_name = temmMatched[0].last_matched_desposal.name;

																//Finding Forced disposal color
																const isForcedSmp = state.forcedSamples.find(
																	f => f.sampleName === temmMatched[0].sample_name
																);
																if (!__.isEmpty(isForcedSmp)) {
																	const getDispColor = state.desposalList.find(
																		d => d.value === isForcedSmp.desposalId
																	);
																	bg_color = getDispColor?.color;
																}
																let header_css = {
																	width: 150,
																	flexDirection: 'row',
																	fontSize: 17,
																	padding: '4px 5px 1px 5px',
																	backgroundColor: bg_color,
																	border: '2px solid #000',
																	textAlign: 'center',
																};
																matchedDisposal.push(
																	<td style={header_css} key={`${ss.sample_name}_${Math.random()}`}>
																		{fromPage === 'sampleDisposalSetting' ? (
																			<DisposalContainer>
																				<div className="data-field">
																					{isExistSample(temmMatched[0].sample_name) ? (
																						<>
																							<IconRemoveOrResetDisposal
																								onClick={() =>
																									resetMatchedDisposal(temmMatched[0].sample_name)
																								}
																								sampleName={temmMatched[0].sample_name}
																								action={'reset'}
																							/>
																							<div>
																								<DisposalDropdown
																									state={state}
																									localDispatch={localDispatch}
																									sampleName={temmMatched[0].sample_name}
																								/>
																							</div>
																						</>
																					) : (
																						<>
																							<IconRemoveOrResetDisposal
																								onClick={() =>
																									removeMatchedDisposal(temmMatched[0])
																								}
																								sampleName={temmMatched[0]}
																								action={'remove'}
																							/>
																							<p> {d_name} </p>
																						</>
																					)}
																				</div>
																			</DisposalContainer>
																		) : (
																			<p>
																				{' '}
																				{d_name}
																				{temmMatched[0].isForcedDisposal ? (
																					<span style={styles.forcedDispAsterisk}>*</span>
																				) : null}{' '}
																			</p>
																		)}
																	</td>
																);
															} else {
																//Finding Forced disposal color
																const isForcedSmp = state.forcedSamples.find(
																	f => f.sampleName === temmMatched[0].sample_name
																);
																if (!__.isEmpty(isForcedSmp)) {
																	const getDispColor = state.desposalList.find(
																		d => d.value === isForcedSmp.desposalId
																	);
																	bg_color = getDispColor?.color;
																}
																matchedDisposal.push(
																	<td
																		style={{
																			width: 150,
																			border: '2px solid #000',
																			backgroundColor: bg_color,
																		}}
																		key={`${ss.sample_name}_${Math.random()}`}
																	>
																		{fromPage === 'sampleDisposalSetting' ? (
																			<DisposalContainer>
																				<div className="data-field">
																					{isExistSample(temmMatched[0].sample_name) ? (
																						<IconRemoveOrResetDisposal
																							onClick={() =>
																								resetMatchedDisposal(temmMatched[0].sample_name)
																							}
																							sampleName={temmMatched[0].sample_name}
																							action={'reset'}
																						/>
																					) : null}
																					<DisposalDropdown
																						state={state}
																						localDispatch={localDispatch}
																						sampleName={temmMatched[0].sample_name}
																						campaignId={temmMatched[0].sample_campaign}
																					/>
																				</div>
																			</DisposalContainer>
																		) : null}
																	</td>
																);
															}
														} else {
															matchedDisposal.push(
																<th
																	style={{ width: 150, border: 'none !important' }}
																	key={`${ss.sample_name}_${Math.random()}`}
																></th>
															);
														}
													});

													/* Display empty cell for disposal process row */
													{
														!disposalThresholdBeforeSamples &&
															getDisposalProcessEmptyCell(matchedDisposal);
													}
													return (
														<>
															<Table
																id={'reporttbl_' + key}
																size={[width, heigth]}
																style={styleshtml.page}
																wrap
																key={`page__${Math.random()}__`}
															>
																<thead className={'stickyHeader reportHeader'}>
																	{headerDetails}

																	<tr
																		className={'sticky-tds'}
																		style={styles.fullWidth}
																		key={`${Math.random()}`}
																	>
																		{probingArr}
																	</tr>
																	<tr style={styles.fullWidth} key={`${Math.random()}`}>
																		{sampleArr}
																	</tr>
																	{reportData?.lockHeader && (
																		<>
																			<tr style={styles.fullWidth} key={`${Math.random()}`}>
																				{sampleObservationArr}
																			</tr>
																			<tr style={styles.fullWidth} key={`${Math.random()}`}>
																				{depthArr}
																			</tr>
																			<tr style={styles.fullWidth} key={`${Math.random()}`}>
																				{lithologyArr}
																			</tr>
																			<tr style={styles.fullWidth} key={`${Math.random()}`}>
																				{colourArr}
																			</tr>
																			{isPageSampleDisposalSetting === true ? (
																				<>
																					<tr style={styles.fullWidth} key={`${Math.random()}`}>
																						{anomalyArr}
																					</tr>
																				</>
																			) : null}
																			<tr style={styles.fullWidth} key={`${Math.random()}`}>
																				{layerObservationArr}
																			</tr>
																		</>
																	)}
																</thead>
																
																{/* {headingRow} */}
																<tbody className="stickyPollutants">{allRows}</tbody>


																{!reportData.selectedPollutantFamilies.length && (
																	<tfoot className="stickyFooter">
																		<tr style={styles.fullWidth}>
																			<td style={styleshtml.disposalNameAtLast}>
																				{client_disposal_process}
																			</td>
																			{matchedDisposal}
																		</tr>
																	</tfoot>
																)}

																{reportData.dataToPrintPdf.length != key + 1 && (
																	<tr style={styleshtml.heightthirty} break></tr>
																)}
															</Table>
														</>
													);
												})}
										</>
									) : (
										''
									)}
								</div>
							</div>
							{fromPage === 'sampleDisposalSetting' ? (
								<ButtonContainer>
									<Button outline={true} color={'success'} onClick={onClose}>
										{cancel_btn}
									</Button>
									<Button
										color={'success'}
										disabled={loaderForcedSamples}
										onClick={() => getForcedSamples()}
									>
										{common_btn_save}
										{loaderForcedSamples && (
											<Spinner
												size="sm"
												style={{ marginLeft: '10px', color: 'var(--clr-aexdo)' }}
												className="btn-spinner"
											/>
										)}
									</Button>
								</ButtonContainer>
							) : null}
						</>
					) : (
						<NoResultFound className="page_404">
							<div className="container">
								<div className="row">
									<div className="col-sm-12 ">
										<div className="col-sm-10 text-center center">
											<div className="four_zero_four_bg"></div>
										</div>
									</div>
								</div>
							</div>
						</NoResultFound>
					)}
				</>
			)}
		</>
	);
}

function DisposalDropdown({ state, localDispatch, sampleName, campaignId }) {
	function handleSelectForcedDisposal(sample_name, event, campaign = '') {
		let forcedSamples = [...state.forcedSamples];
		const forcedSample = forcedSamples.find(s => s.sampleName === sample_name);
		if (!__.isEmpty(forcedSample)) {
			forcedSample.desposalId = event.value;
			forcedSample.desposalName = event.label;
			forcedSample.forceSelected = true;
		} else {
			const obj = {
				sampleCampaign: campaign,
				sampleName: sample_name,
				desposalId: event.value,
				desposalName: event.label,
				forceSelected: true,
			};
			forcedSamples = forcedSamples.concat(obj);
		}
		localDispatch({
			forcedSamples: forcedSamples,
		});
	}

	function getSelectedForcedDisposal(sample_name) {
		let forcedSamples = [...state.forcedSamples];
		let desposalList = [...state.desposalList];
		const forcedSample = forcedSamples.find(
			s => s.sampleName === sample_name && s.forceSelected === true
		);
		if (!__.isEmpty(forcedSample)) {
			const findSelDisp = desposalList.filter(s => s.value === forcedSample.desposalId);
			return findSelDisp;
		} else {
			return [];
		}
	}

	const customStyles = {
		menu: provided => ({
			...provided,
			maxHeight: '500', // Adjust the maximum height as needed
			overflow: 'auto',
		}),
	};

	return (
		<Select2
			options={state.desposalList}
			// defaultMenuIsOpen
			isMulti={false}
			name="multiorigin"
			placeholder={'Select'}
			value={getSelectedForcedDisposal(sampleName)}
			onChange={e => handleSelectForcedDisposal(sampleName, e, campaignId)}
			closeMenuOnSelect={true}
			className="basic-multi-select"
			classNamePrefix="select"
			isSearchable={true}
			menuPlacement="top"
			styles={customStyles}
		/>
	);
}

function IconRemoveOrResetDisposal({ onClick, sampleName, action }) {
	return (
		<p>
			<span>
				<button onClick={() => onClick(sampleName)}>
					<Tooltip title={action === 'reset' ? 'Reset' : 'Remove'}>
						<Icon>{action === 'reset' ? 'keyboard_backspace' : 'highlight_off'} </Icon>
					</Tooltip>
				</button>
			</span>
		</p>
	);
}

const NoResultFound = styled.div`
	.center {
		margin: 0 auto;
	}
	.four_zero_four_bg {
		background-image: url('/img/noResult.png');
		height: 400px;
		background-position: center;
		background-repeat: no-repeat;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: end;
	margin-block-start: 0.8rem;
	.btn {
		padding: 4px 25px;
		margin-bottom: 0px;
	}
`;

const DisposalContainer = styled.div`
	.data-field {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
		.basic-multi-select {
			width: 7rem;
			font-size: 13px;
			font-weight: 500;
		}
		div {
			margin: 0;
		}
		p {
			margin: 0;
		}
		p:first-child {
		}
		// p:last-child span{
		// 	font-size: 1.2em;
		// 	cursor: pointer;
		// }
		button {
			border: none;
			color: inherit;
			background-color: transparent;
			min-width: 1.5rem;
			padding: 0;
			line-height: 1;
			span {
				font-size: 1.2em;
				//font-weight: 700;
			}
		}
	}
`;
