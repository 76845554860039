import React from "react";
import { Button, Modal, ModalFooter, Row, Table } from 'reactstrap';
import * as ChangeAppLanguageFront from '../../../../Layout/ChangeAppLanguageFront';

let tlang = ChangeAppLanguageFront.translateLanguage;
const user_profile_detail = tlang('user_profile_detail') || 'Details';
const excelupload_campaign = tlang('excelupload_campaign') || 'Campaign';
const analysis_file_name = tlang('analysis_file_name') || 'File name';
const analysis_sheet_name = tlang('analysis_sheet_name') || 'Sheet name';
const viewExcel_Row_of_header = tlang('viewExcel_Row_of_header') || 'Row of test family header';
const viewExcel_Rownumofheader = tlang('viewExcel_Rownumofheader') || 'Row number of header';
const viewExcel_Rowofprobings = tlang('viewExcel_Rowofprobings') || 'Row of probings';
const viewExcel_Rowofprobingsnum = tlang('viewExcel_Rowofprobingsnum') || 'Row of probings number';
const excelupload_test_matched = tlang('excelupload_test_matched') || 'Test Matched';
const excelupload_test_not_found = tlang('excelupload_test_not_found') || 'Test Not Found';
const excelupload_test_matched_duplicately = tlang('excelupload_test_matched_duplicately') || 'Test Matched Duplicately';
const common_btn_close = tlang('common_btn_close') || 'Close';

export default function ModalViewExcelDetails({ isOpen, onClose, selectedRecord, tableApproxWidth, totalMatchedTestFamily, totalUnMatchedTestFamily, totalDuplicateTestFamily, exceptionConst }) {

	const scrollWrapper1 = (event) => {
		var wrapper1 = document.getElementById('scrollbarWrapper1');
		var wrapper2 = document.getElementById('scrollbarWrapper2');

		wrapper1.onscroll = function () {
			wrapper2.scrollLeft = wrapper1.scrollLeft;
		};
	}

	const scrollWrapper2 = (event) => {
		var wrapper1 = document.getElementById('scrollbarWrapper1');
		var wrapper2 = document.getElementById('scrollbarWrapper2');

		wrapper2.onscroll = function () {
			wrapper1.scrollLeft = wrapper2.scrollLeft;
		};
	}

	const headersList = () => {
		let allKeys = Object.keys(selectedRecord.sheet_resp_header)
		let header = []
		allKeys.map((key, ind) => {
			if (key != "remove" && key != "0" && key != "1") {
				header.push(<th key={Math.random()}>{selectedRecord.sheet_resp_header[key]}</th>)
			}
		})
		return (
			<>
				<tr key={Math.random()}>{header}</tr>
			</>
		)
	}

	const renderTableData = () => {
		if (selectedRecord.sheet_resp_data) {
			let prob_smpl = []
			selectedRecord.probing_data.map((probing) => {
				probing.sample_list.map((sample) => {
					if (!prob_smpl.includes(sample.sample_name)) {
						prob_smpl.push(sample.sample_name)
					}
				})
			})
			return selectedRecord.sheet_resp_data.map((result, mainindex) => {
				let col = Object.keys(result)
				return (
					<tr key={Math.random()}>
						{col.map((val, index) => {
							let parentTestFamilyName = '';

							if (val != 'Test_Parent_Id' && val != 'Test_Child_Id' && val != "Test_Match_Status" && val != "Test_Match_Data" && val != "Test_Parent_Familyname") {
								let validRowColor = ""
								if (val == 'Testfamily') {
									//let isTestMatched = result['isTestMatched'];
									let isTestMatched = result['Test_Match_Status'];
									if (typeof isTestMatched !== 'undefined') {
										if (isTestMatched == "Match") {
											validRowColor = "green";
											if (result['Test_Parent_Familyname']) {
												parentTestFamilyName = result['Test_Parent_Familyname'];
											}
										}
										if (isTestMatched == "UnMatch") {
											validRowColor = "#ff4861";
										}
										if (isTestMatched == "Duplicate") {
											validRowColor = "#a0a133";

											if (result['Test_Parent_Familyname']) {
												parentTestFamilyName = result['Test_Parent_Familyname'];
											} else {
												if (result['Test_Parent_Id'] != "") {
													let parentId = result['Test_Parent_Id'];
													let parentRec = result['Test_Match_Data'].filter(l => l.test_parent_id == parentId);
													if (parentRec.length > 0) {
														parentTestFamilyName = parentRec[0].test_parent_name;
													}
												}
											}
										}
										if (isTestMatched == "") {
											validRowColor = "#ff4861";
										}
									} else {
										validRowColor = "#ff4861";
									}
								}

								if (prob_smpl.includes(val)) {

									// if((typeof(result[col[index]]) !="number" && result[col[index]].toString().includes("<") == false && result[col[index]].toString().includes("-") == false && result[col[index]] != "<LQ" && result[col[index]] != "n.d." && result[col[index]] != "nd") || (result[col[index]].toString().trim() == "-")){
									//   validRowColor = "#ff4861"
									// }

									//if string value is not satisfied with exception list then highight it as red background colour
									if (typeof result[col[index]] == "string" && result[col[index]].toString().trim() != "-") {
										let inr_flag = false;
										for (let fil_text of exceptionConst) {
											if (result[col[index]].includes(fil_text) && inr_flag == false) {
												inr_flag = true;
												break;
											}
										}
										if (inr_flag == false) {
											validRowColor = "#ff4861"
										}
									} else {
										if (typeof result[col[index]] == "string" && result[col[index]].toString().trim() == "-") {
											validRowColor = "#ff4861"
										}
									}
								}

								var th = "";
								return <td key={Math.random()} style={{ background: validRowColor, color: validRowColor ? "#fff" : "" }}>{result[col[index]]}
									<label style={{ fontSize: "smaller" }}>{parentTestFamilyName != "" ? parentTestFamilyName : ""}</label>
								</td>
							}
						})}
					</tr>
				)
			})
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			toggle={onClose}
			className={`modal-dialog--success modal-dialog--header`}
			style={{ width: "80%", minWidth: "80%", maxWidth: "80%" }}
		>
			<div className="modal__header">
				<button className="lnr lnr-cross modal__close-btn" type="button" onClick={onClose} />
				<h4 className="bold-text  modal__title">{user_profile_detail}</h4>
			</div>
			<div className="modal__body">
				<Row>
					{selectedRecord &&
						<>
							<div className="table-responsive">
								<Table striped bordered hover>
									<thead className='sheetHeader'>
										<tr>
											<th>{excelupload_campaign}</th>
											<th>{analysis_file_name}</th>
											<th>{analysis_sheet_name}</th>
											<th>{viewExcel_Row_of_header}</th>
											<th>{viewExcel_Rownumofheader}</th>
											<th>{viewExcel_Rowofprobings}</th>
											<th>{viewExcel_Rowofprobingsnum}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{selectedRecord.campaign_name}</td>
											<td>{selectedRecord.file_name}</td>
											<td>{selectedRecord.sheet_name}</td>
											<td>{selectedRecord.rows_for_header}</td>
											<td>{selectedRecord.row_for_header_no}</td>
											<td>{selectedRecord.rows_for_head_probing}</td>
											<td>{selectedRecord.row_for_head_probing_no}</td>
										</tr>
									</tbody>
								</Table>
							</div>

							<div style={{ marginLeft: "20px", marginTop: "20px" }}>
								<div style={{ float: "left", marginRight: "20px" }}><div style={{ float: "left", height: "20px", width: "20px", marginBottom: "20px", marginRight: "8px", clear: "both", backgroundColor: "green" }}></div>  <b>{totalMatchedTestFamily}</b> {excelupload_test_matched}</div>
								<div style={{ float: "left" }}><div style={{ float: "left", height: "20px", width: "20px", marginBottom: "20px", marginRight: "8px", clear: "both", backgroundColor: "#ff4861" }}></div>  <b>{totalUnMatchedTestFamily}</b> {excelupload_test_not_found}</div>
								<div style={{ float: "left", marginLeft: "20px" }}><div style={{ float: "left", height: "20px", width: "20px", marginBottom: "20px", marginRight: "8px", clear: "both", backgroundColor: "#a0a133" }}></div>  <b>{totalDuplicateTestFamily}</b> {excelupload_test_matched_duplicately}</div>
							</div>

							<div onScroll={(event) => scrollWrapper1(event)} id="scrollbarWrapper1" style={{ overflowX: "scroll" }}><div style={{ width: tableApproxWidth }} id="scrollbarDiv1"></div></div>
							<div onScroll={(event) => scrollWrapper2(event)} id="scrollbarWrapper2">
								<div style={{ width: tableApproxWidth }} id="scrollbarDiv2">
									<Table style={{ whiteSpace: "nowrap" }} striped bordered hover id="recordsTable" className="">
										<thead className='sheetHeader'>
											<>{headersList()}</>
										</thead>
										<tbody className='sheetBody'>
											<>{renderTableData()}</>
										</tbody>
									</Table>
								</div>
							</div>
						</>
					}
				</Row>
			</div>
			<ModalFooter>
				<Button outline={true} color={'success'} onClick={onClose}>{common_btn_close}</Button>
			</ModalFooter>
		</Modal>
	);
}

